import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Loading from '@/components/widgets/Loading.vue'
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})
export default {
  components: {
    DatePicker,
    Loading,
    Paginate
  },
  data: function() {
    return {
      role: "",
      branch: "",
      loading: false,
      busy: false,
      apiStatus: 'ไม่พบข้อมูล',
      status: null,
      doc: {
        start: null,
        end: null
      },
      duedate: {
        start: null,
        end: null
      },
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      keyword: '',
      fields: [
        { 
          key: 'document_no', 
          label: 'เลขที่ใบส่งสินค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        // { 
        //   key: 'bill.bill_no', 
        //   label: 'เลขที่บิล',
        //   thClass: 'font-weight-bold text-xs-1/2',
        //   tdClass: 'td-top text-xs-1/2',
        //   sortable: true 
        // },
        { 
          key: 'bill.bill_customer_name', 
          label: 'ชื่อลูกค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'bill.bill_customer_shipping_address', 
          label: 'ที่อยู่',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'creator_detail.name', 
          label: 'ผู้จัดทำ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'total_price', 
          label: 'ยอดสุทธิ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'remark', 
          label: 'หมายเหตุ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-left',
          sortable: true 
        },
        { 
          key: 'status', 
          label: 'สถานะ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        }
      ],
      items: []
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
    },
    ondeleted(id) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการจะลบข้อมูลนี้หรือไม่?</span>'+
          '<span class="text-base text-left text-gray-500">หากคุณยืนยันแล้ว ข้อมูลจะไม่สามารถกู้คืนกลับมาได้?</span>'+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          cancelButtonColor: '#dc4a38',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          showCloseButton: true,
      }).then(async confirm => {
        if(confirm.value){
          this.loading = true
          this.onConfirmDelete(id)
        }
      })
    },
    async onConfirmDelete(id) {
      try {
        const param = {
          id: id
        }
        const result = await this.$store.dispatch('Bills/delete', param, { root: true })
        setTimeout(() => {
          this.loading = false  
          if (result.status === 204) {            
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
                '<div class="d-flex flex-column">'+
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
                '<span class="text-base text-left text-gray-500">ลบข้อมูลเรียบร้อยแล้ว</span>'+
                '</div>'+
              '</div>',
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: 'swal2-custom-rounded',
                  closeButton: 'text-3xl swal2-custom-close',
                  actions: 'justify-content-end',
                  contant: 'd-flex flex-row justify-content-around px-2',
                  title: 'd-none',
                  confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
                },
                cancelButtonColor: '#dc4a38',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: "ตกลง",
                showCancelButton: false,
                showCloseButton: true,
            }).then(async confirm => {
              if(confirm.value){
                this.onInitData(1)
              }
            })          
          }
        }, 1000)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    oncreate() {
      this.$router.push("/billing/create")
    },
    onInfo(id) {
      this.$emit('componentInfo', { 
        status: true,
        component: 'billing-info'
      })
    },
    onedit(uuid) {
      this.$router.push("/billing/create/" + uuid)
    },
    async onInitData(currentPage) {
      try {
        // this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'

        const page = currentPage ? currentPage : 1
        const url = `${Vue.prototype.$host}/api/v1/document/delivery?page=${page}&perPage=8&keyword=${this.keyword}`
        const param = {
          keyword: this.keyword,
          url: url
        }
        const result = await this.$store.dispatch('Bills/getAll', param, { root: true })
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          
          if (result) {
            this.currentData = result.data;
            this.items = result.data.data
            this.totalItem = result.data.total
          } else {
            this.items = []
            this.totalItem = 0
          }
        }, 500)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onPrintProductionHandler() {
      this.$emit('componentInfo', { 
        status: true,
        component: 'production'
      })
    },
    generateQRcode(uuid) {
      try {
        this.loading = true
        setTimeout(async () => {
          const result = await this.$axios.get(
            this.$host + "/api/v1/document/downloadDeliveryPDF/" + uuid,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              responseType: "blob"
            }
          )
          const blob = new Blob([result.data], {type: 'application/pdf'});
          const objectUrl = URL.createObjectURL(blob)
          window.open(objectUrl,'targetWindow',
          `toolbar=no,
          location=no,
          status=no,
          menubar=no,
          scrollbars=yes,
          resizable=yes,
          width=SomeSize,
          height=SomeSize`);

          // const link = document.createElement("a");
          // link.href = objectUrl;
          // this.pdfsrc = objectUrl
          // link.setAttribute(
          //   "download",
          //   `ใบเสนอราคา-${name}.pdf`
          // );
          // document.body.appendChild(link);
          // link.click();
    
          // URL.revokeObjectURL(objectUrl)
          this.loading = false
        }, 1000)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    generateInvoice(delivery_uuid) {
      try {
        this.loading = true
        setTimeout(async () => {
          const result = await this.$axios.get(
            this.$host + "/api/v1/invoice-pdf/" + delivery_uuid,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              responseType: "blob"
            }
          )
          const blob = new Blob([result.data], {type: 'application/pdf'});
          const objectUrl = URL.createObjectURL(blob)
          window.open(objectUrl,'targetWindow',
          `toolbar=no,
          location=no,
          status=no,
          menubar=no,
          scrollbars=yes,
          resizable=yes,
          width=SomeSize,
          height=SomeSize`);

          // const link = document.createElement("a");
          // link.href = objectUrl;
          // this.pdfsrc = objectUrl
          // link.setAttribute(
          //   "download",
          //   `ใบเสนอราคา-${name}.pdf`
          // );
          // document.body.appendChild(link);
          // link.click();
    
          // URL.revokeObjectURL(objectUrl)
          this.loading = false
        }, 1000)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onConvertToProduction(uuid){
      this.$router.push({ path: 'production/create', query: { q: uuid } })
    },
  },
  mounted() {
    this.getRole()
    this.onInitData(1)
  }
}
